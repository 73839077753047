const React = require('react');
const PropTypes = require('prop-types');
const Select = require('../../inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('../../inputs/Select/SelectItem.react');
const ChartConfigDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigDimension');
const T = require('../../Typography/Typography.react');
const InputValidator = require('../../InputValidator/InputValidator.react');
const DimensionSelectItem = require('./models/DimensionSelectItem');
const ChartConfigTimeDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimension');
const ChartConfigProbabilityDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigProbabilityDimension/ChartConfigProbabilityDimension');
const ChartConfigClientDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigClientDimension/ChartConfigClientDimension');
const ChartConfigRevenuesDimension = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigRevenuesDimension/ChartConfigRevenuesDimension');
const TimeOptions = require('./ChartSidebarDimensionOptions/ChartSidebarDimensionTimeOptions.react');

/**
 *
 * @param {string} axisLabel
 * @param {string} axisKey
 * @param {ChartConfigDimension} dimension
 * @param error
 * @param onChange
 * @param {DimensionSelectItem[]} availableDimensions
 * @return {JSX.Element}
 * @constructor
 */
const ChartSidebarDimension = ({
  axisLabel,
  axisKey,
  dimension,
  getError,
  onChange,
  availableDimensions,
  updateErrors,
}) => {
  function handleChange(e, item) {
    let updatedDimension = null;
    switch (item.value) {
      case ChartConfigTimeDimension.KEY:
        updatedDimension = new ChartConfigTimeDimension();
        break;
      case ChartConfigProbabilityDimension.KEY:
        updatedDimension = new ChartConfigProbabilityDimension();
        break;
      case ChartConfigClientDimension.KEY:
        updatedDimension = new ChartConfigClientDimension();
        break;
      case ChartConfigRevenuesDimension.KEY:
        updatedDimension = new ChartConfigRevenuesDimension();
        break;
      default:
        throw new Error(`'${item.value}' is not a valid chart config dimension key`);
    }
    onChange(axisKey, updatedDimension);
  }

  function handleOptionsChange(e) {
    const { value } = e.target;
    const updatedDimension = dimension;
    updatedDimension.grouping.value = value;
    onChange(axisKey, updatedDimension);
  }

  const getItems = () => availableDimensions.map((availableDimension) => (
    <SelectItem key={availableDimension.key} value={availableDimension.key}>
      {availableDimension.label}
    </SelectItem>
  ));

  const getOptions = () => {
    if (dimension instanceof ChartConfigTimeDimension) {
      return <TimeOptions value={dimension.grouping.value} onChange={handleOptionsChange} />;
    }
    if (dimension instanceof ChartConfigProbabilityDimension) {
      return null;
    }
    if (dimension instanceof ChartConfigClientDimension) {
      return null;
    }
    return null;
  };

  return (
    <div className="wethod-chart-sidebar__dimension">
      <div><T size={T.SIZES.PX14}>{axisLabel}</T></div>
      <InputValidator updateErrors={updateErrors}
        constraints={['required']}>
        <Select errorText={getError(axisKey)}
          id={`${axisKey}-dimension`}
          className="wethod-chart-sidebar__dimension-select"
          onChange={handleChange}
          label="Select dimension"
          value={dimension ? dimension.key : ''}
          name={axisKey}>
          <SelectItem key="empty" value="" />
          {getItems()}
        </Select>
      </InputValidator>
      <div>
        {getOptions()}
      </div>
    </div>
  );
};

ChartSidebarDimension.propTypes = {
  axisLabel: PropTypes.string.isRequired,
  axisKey: PropTypes.string.isRequired,
  getError: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  dimension: PropTypes.instanceOf(ChartConfigDimension).isRequired,
  availableDimensions: PropTypes.arrayOf(DimensionSelectItem).isRequired,
  updateErrors: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimension;
