const React = require('react');
const PropTypes = require('prop-types');
const FilterResume = require('./FilterResume.react');
const TextField = require('../inputs/TextField/OutlinedTextField/OutlinedTextField.react');

const AdvancedSearchBodyActions = ({ totalActive, onReset, onSearch, title, highlight }) => (
  <div className="wethod-advanced-filters__actions">
    <FilterResume total={totalActive} onReset={onReset} highlight={highlight} title={title} />
    <div className="wethod-advanced-filters__filter">
      <TextField name="search" placeholder="Search filter" onChange={onSearch} />
    </div>
  </div>
);

AdvancedSearchBodyActions.defaultProps = {
  totalActive: 0,
  onSearch: undefined,
  onReset: undefined,
  highlight: undefined,
  title: undefined,
};

AdvancedSearchBodyActions.propTypes = {
  totalActive: PropTypes.number,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  highlight: PropTypes.bool,
  title: PropTypes.string,
};

module.exports = AdvancedSearchBodyActions;
