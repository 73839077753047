const React = require('react');
const PropTypes = require('prop-types');
const Sidebar = require('../../../../../../../common/react/charts/ChartSidebar/ChartSidebar.react');
const ChartConfig = require('../../models/ChartConfig');
const BarChartConfigDimensions = require('../../models/BarChartConfigDimensions');
const Chart = require('../../models/Chart');
const ChartModel = require('../../../../../../../models/Chart');
const sidebarService = require('../../../../../../../common/react/sidebar/SidebarManager/services/SidebarService');

/**
 * Sidebar used to create a new chart.
 */
class CreateSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaiting: false,
      serverErrors: [],
    };

    this.defaultConfig = new ChartConfig('New chart', '-', ChartConfig.TYPE_BAR, [], new BarChartConfigDimensions(null, null));

    this.onSave = this.onSave.bind(this);
  }

  /**
   * @param {ChartConfig} config
   */
  onSave(config) {
    const chart = new Chart(null, this.props.parent, this.props.dataSource, config);
    this.setState({ isWaiting: true });
    ChartModel.create(chart)
      .done((serializedChart) => {
        const createdChart = Chart.fromJSON(serializedChart);
        sidebarService.closeSidebar();
        this.setState({ isWaiting: false });
        this.props.onSave(createdChart);
      })
      .fail((errors) => {
        this.setState({
          isWaiting: false,
          serverErrors: errors,
        });
      });
  }

  render() {
    return (
      <Sidebar onCloseClick={this.props.onCloseClick}
        title="New chart"
        config={this.defaultConfig}
        errors={this.state.serverErrors}
        isSaving={this.state.isWaiting}
        onSave={this.onSave} />
    );
  }
}

CreateSidebar.defaultProps = {
  parent: null,
};

CreateSidebar.propTypes = {
  parent: PropTypes.number,
  dataSource: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

module.exports = CreateSidebar;
