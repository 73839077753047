const React = require('react');
const PropTypes = require('prop-types');
const Transition = require('../../Transition/Transition.react');

require('./style.scss');

const SlideInTransition = ({ children, triggered, animateFirstMount }) => (
  <Transition triggered={triggered} className="wethod-transition-slide-in" animateFirstMount={animateFirstMount} timeout={400}>
    {children}
  </Transition>
);

SlideInTransition.defaultProps = {
  animateFirstMount: false,
};

SlideInTransition.propTypes = {
  /**
   * Element to animate.
   */
  children: PropTypes.node.isRequired,
  /**
   * Show the component; triggers the enter or exit states
   */
  triggered: PropTypes.bool.isRequired,
  /**
   * Normally a component is not transitioned if it is shown when the `<Transition>` component
   * mounts.
   * If you want to transition on the first mount set `animateFirstMount` to `true`, and the
   * component will transition in as soon as the `<Transition>` mounts.
   */
  animateFirstMount: PropTypes.bool,
};

module.exports = SlideInTransition;
