const React = require('react');
const WarningAccordion = require('../WarningAccordion.react');
const UnapprovedBudgets = require('../../../containers/dashboard/unapproved-budgets/UnapprovedBudgets');

const UnapprovedBudgetsAccordion = ({ counter }) => (
  <WarningAccordion
    title="Unapproved budgets"
    subtitle="Budgets for the following opportunities/projects must be approved in order to freeze data."
    counter={counter}>
    <UnapprovedBudgets />
  </WarningAccordion>
);

module.exports = UnapprovedBudgetsAccordion;
