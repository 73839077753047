/**
 * @abstract
 */
class ChartConfigProbabilityDimensionGrouping {
  /**
   * @param {string} type
   */
  constructor(type) {
    this.type = type;
  }
}

module.exports = ChartConfigProbabilityDimensionGrouping;
