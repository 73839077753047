const ChartConfigTableColumn = require('./ChartConfigTableColumn');

module.exports = class ChartConfigTable {
  /**
   * Config of table view related to chart.
   * @param {ChartConfigTableColumn[]} columns
   */
  constructor(columns) {
    this.columns = columns;
  }

  /**
   * @return {ChartConfigTableColumn[]}
   */
  getColumns() {
    return this.columns;
  }

  /**
   * @param data
   * @return {ChartConfigTable}
   */
  static fromJSON(data) {
    const columns = data.columns.map(ChartConfigTableColumn.fromJSON);
    return new ChartConfigTable(columns);
  }
};
