const ChartConfigFilterTime = require('./ChartConfigFilterTime');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterTimeRangeIncluded extends ChartConfigFilterTime {
  /**
   * @param {string[]} values
   * @param {string} precision
   */
  constructor(values, precision) {
    super(ChartConfigFilter.TYPE_IN, precision);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterTimeRangeIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterTimeRangeIncluded(data.values, data.precision);
  }
};
