const React = require('react');

const BarChartIcon = () => (
  <svg width="34" height="29" viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Bar Chart">
      <path id="Vector" d="M31.4578 7.07468H26.8313C25.9279 7.07468 25.1892 7.81343 25.1892 8.71685V27.894H21.1482V1.77709C21.1482 0.873672 20.4094 0.134924 19.506 0.134924H14.8795C13.9761 0.134924 13.2373 0.873672 13.2373 1.77709V27.894H8.81084V15.6566C8.81084 14.7532 8.0721 14.0144 7.16867 14.0144H2.54217C1.63875 14.0144 0.9 14.7532 0.9 15.6566V27.2229C0.9 28.1263 1.63875 28.865 2.54217 28.865H31.4578C32.3613 28.865 33.1 28.1263 33.1 27.2229V8.71685C33.1 7.81343 32.3613 7.07468 31.4578 7.07468ZM7.83976 27.894H2.54217C2.1733 27.894 1.87108 27.5917 1.87108 27.2229V15.6566C1.87108 15.2877 2.1733 14.9855 2.54217 14.9855H7.16867C7.53754 14.9855 7.83976 15.2877 7.83976 15.6566V27.894ZM14.2084 27.894V1.77709C14.2084 1.40823 14.5106 1.10601 14.8795 1.10601H19.506C19.8749 1.10601 20.1771 1.40822 20.1771 1.77709V27.894H14.2084ZM32.1289 27.2229C32.1289 27.5917 31.8267 27.894 31.4578 27.894H26.1602V8.71685C26.1602 8.34798 26.4625 8.04577 26.8313 8.04577H31.4578C31.8267 8.04577 32.1289 8.34798 32.1289 8.71685V27.2229Z" fill="#212121" stroke="#212121" strokeWidth="0.2" />
    </g>
  </svg>

);

module.exports = BarChartIcon;
