module.exports = {
  FREE: 'free',
  IN_REVIEW: 'in_review',
  PAUSED: 'paused',
  FROZEN: 'frozen',
};

module.exports.COLORS = {
  [module.exports.FREE]: '#28B869',
  [module.exports.IN_REVIEW]: '#009FE3',
  [module.exports.PAUSED]: '#FAC03D',
  [module.exports.FROZEN]: '#C4C4C4',
};

module.exports.LABEL = {
  [module.exports.FREE]: 'Free',
  [module.exports.IN_REVIEW]: 'Data review',
  [module.exports.PAUSED]: 'Paused data edit',
  [module.exports.FROZEN]: 'Frozen data',
};

module.exports.PERMISSIONS = {
  [module.exports.FREE]: 'view',
  [module.exports.IN_REVIEW]: 'view',
  [module.exports.PAUSED]: 'pause',
  [module.exports.FROZEN]: 'freeze',
};
