const ChartConfigFilterProbability = require('./ChartConfigFilterProbability');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterProbabilityIncluded extends ChartConfigFilterProbability {
  constructor(values) {
    super(ChartConfigFilter.TYPE_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterProbabilityIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterProbabilityIncluded(data.values);
  }
};
