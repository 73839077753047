const ChartConfigDimension = require('../ChartConfigDimension');

class ChartConfigRevenuesDimension extends ChartConfigDimension {
  constructor() {
    super(ChartConfigRevenuesDimension.KEY);
  }

  /**
   * @return {ChartConfigRevenuesDimension}
   */
  static fromJSON() {
    return new ChartConfigRevenuesDimension();
  }
}

ChartConfigRevenuesDimension.KEY = 'revenues';

module.exports = ChartConfigRevenuesDimension;
