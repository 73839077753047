/**
 * @abstract
 */
class ChartConfigClientDimensionGrouping {
  /**
   * @param {string} type
   */
  constructor(type) {
    this.type = type;
  }

  /**
   * @abstract
   * @return {boolean}
   */
  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    throw new Error('isGrouped must be defined in classed that extends ChartConfigClientDimensionGrouping');
  }
}

module.exports = ChartConfigClientDimensionGrouping;
