const React = require('react');

const StackedBarChartIcon = () => (
  <svg width="34" height="29" viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Stacked Chart">
      <path id="Vector" d="M31.4578 7.07468H24.8313C23.9279 7.07468 23.1892 7.81343 23.1892 8.71685V27.894H22.2794V1.77709C22.2794 0.873672 21.5406 0.134924 20.6372 0.134924H13.3313C12.4279 0.134924 11.6892 0.873672 11.6892 1.77709V27.894H10.8108V15.6566C10.8108 14.7532 10.0721 14.0144 9.16867 14.0144H2.54217C1.63875 14.0144 0.9 14.7532 0.9 15.6566V27.2229C0.9 28.1263 1.63875 28.865 2.54217 28.865H31.4578C32.3613 28.865 33.1 28.1263 33.1 27.2229V8.71685C33.1 7.81343 32.3613 7.07468 31.4578 7.07468ZM9.83976 27.894H2.54217C2.1733 27.894 1.87108 27.5917 1.87108 27.2229V15.6566C1.87108 15.2877 2.1733 14.9855 2.54217 14.9855H9.16867C9.53754 14.9855 9.83976 15.2877 9.83976 15.6566V27.894ZM12.6602 27.894V1.77709C12.6602 1.40823 12.9625 1.10601 13.3313 1.10601H20.6372C21.0061 1.10601 21.3083 1.40822 21.3083 1.77709V27.894H12.6602ZM32.1289 27.2229C32.1289 27.5917 31.8267 27.894 31.4578 27.894H24.1602V8.71685C24.1602 8.34798 24.4625 8.04577 24.8313 8.04577H31.4578C31.8267 8.04577 32.1289 8.34798 32.1289 8.71685V27.2229Z" fill="#212121" stroke="#212121" strokeWidth="0.2" />
      <line id="Line 11" x1="1" y1="19.5" x2="10" y2="19.5" stroke="#212121" />
      <line id="Line 16" x1="1" y1="23.5" x2="10" y2="23.5" stroke="#212121" />
      <line id="Line 12" x1="12" y1="19.5" x2="22" y2="19.5" stroke="#212121" />
      <line id="Line 15" x1="12" y1="10.5" x2="22" y2="10.5" stroke="#212121" />
      <line id="Line 13" x1="24" y1="23.5" x2="33" y2="23.5" stroke="#212121" />
      <line id="Line 14" x1="24" y1="15.5" x2="33" y2="15.5" stroke="#212121" />
    </g>
  </svg>
);

module.exports = StackedBarChartIcon;
