const React = require('react');
const PropTypes = require('prop-types');
const Chart = require('../../../../../../../common/react/charts/Chart/Chart.react');
const imageSrc = require('./images/chart-empty-state.svg');
const T = require('../../../../../../../common/react/Typography/Typography.react');

const InsightLevelChartEmptyState = ({ chartTitle, chartDescription, message }) => (
  <Chart title={chartTitle} description={chartDescription}>
    <div className="wethod-insight-level__chart-empty-state">
      <div className="wethod-insight-level__chart-empty-state-content">
        <div className="wethod-insight-level__chart-empty-state-message">
          <T size={T.SIZES.PX12} weight={T.WEIGHTS.SEMIBOLD} uppercase>{message}</T>
        </div>
        <img className="wethod-insight-level__chart-empty-state-image" src={imageSrc} alt="chart empty state" />
      </div>
    </div>
  </Chart>
);

InsightLevelChartEmptyState.propTypes = {
  message: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
  chartDescription: PropTypes.string.isRequired,
};

module.exports = InsightLevelChartEmptyState;

module.exports = InsightLevelChartEmptyState;
