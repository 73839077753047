const React = require('react');
const isEqual = require('react-fast-compare');
const PropTypes = require('prop-types');
const ChartSidebarStep = require('./ChartSidebarStep.react');
const TextField = require('../../inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const TextArea = require('../../inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const InputValidator = require('../../InputValidator/InputValidator.react');
const BarTypeOption = require('./ChartSidebarBarTypeOption.react');
const StackedBarTypeOption = require('./ChartSidebarStackedBarTypeOption.react');
const RadioGroup = require('../../inputs/RadioGroup/RadioGroup.react');
const DataSegmentStep = require('./ChartSidebarDataSegmentStep.react');
const ChartConfig = require('../../../../apps/core/modules/insights/revenues/models/ChartConfig');
const ProbabilityRangeFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterProbabilityRange');
const ProbabilityFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterProbability');
const ClientFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterClient');
const ClientIncludedFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterClientIncluded');
const TimeFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterTime');
const TimeRelativeFilter = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigFilter/ChartConfigFilterTimeRelative');
const DimensionsStep = require('./ChartSidebarDimensionsStep.react');
const BarChartConfigDimensions = require('../../../../apps/core/modules/insights/revenues/models/BarChartConfigDimensions');
const Sidebar = require('../../sidebar/Sidebar.react');
require('./style.scss');

class ChartSidebar extends React.Component {
  static buildFilter(target, value) {
    switch (target) {
      case ProbabilityFilter.TARGET:
        return new ProbabilityRangeFilter(value.min, value.max);
      case ClientFilter.TARGET:
        return new ClientIncludedFilter(value);
      case TimeFilter.TARGET:
        return new TimeRelativeFilter(value);
      default:
        throw new Error(`${target} is not a valid filter target`);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      config: props.config,
      errors: {},
    };

    this.updateErrors = this.updateErrors.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getInputErrors = this.getInputErrors.bind(this);
    this.onFilterInputChange = this.onFilterInputChange.bind(this);
    this.handleFiltersReset = this.handleFiltersReset.bind(this);
    this.onDimensionChange = this.onDimensionChange.bind(this);
    this.getErrorText = this.getErrorText.bind(this);
    this.onSave = this.onSave.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const serverErrorsChanged = prevProps.errors !== this.props.errors;
    if (serverErrorsChanged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ errors: this.getErrors() });
    }
  }

  handleFiltersReset() {
    const { config } = this.state;
    config.clearFilters();
    this.setState({ config });
  }

  handleCancelClick() {
    this.props.onCloseClick();
  }

  onFilterInputChange(name, value) {
    this.setState((state) => {
      const config = state.config.clone();
      const filter = ChartSidebar.buildFilter(name, value);
      config.addFilter(filter);

      return { config };
    });
  }

  /**
   *
   * @param {string} axis
   * @param {ChartConfigDimension} dimension
   */
  onDimensionChange(axis, dimension) {
    this.setState((state) => {
      const config = state.config.clone();
      config.updateDimension(axis, dimension);

      return { config };
    });
  }

  onSave() {
    this.props.onSave(this.state.config);
  }

  onInputChange(e) {
    const { name, value } = e.target;
    switch (name) {
      case 'description':
      case 'name': {
        this.setState((state) => {
          const config = state.config.clone();
          config[name] = value;
          return { config };
        });
        break;
      }
      case 'type': {
        this.setState((state) => {
          const config = state.config.clone();
          config.type = value;
          config.dimensions = new BarChartConfigDimensions(null, null);

          return { config };
        });
        break;
      }
      default:
        throw new Error('Input name unknown');
    }
  }

  /**
   * Return list of errors for input with given name.
   * @param {string} name
   * @return {{validatorName:string, message:string}[]}
   */
  getInputErrors(name) {
    return this.state.errors[name] ? this.state.errors[name] : [];
  }

  /**
   * Return error message for input with given name.
   * @param {string} inputName
   * @return {string}
   */
  getErrorText(inputName) {
    const inputErrors = this.getInputErrors(inputName);
    return inputErrors.length ? inputErrors[0].message : '';
  }

  getErrors() {
    const serverErrorsToClientMap = {
      'config[dimensions]': 'y',
    };
    const serverErrors = this.props.errors;
    const clientErrors = this.state.errors;
    const errors = { ...clientErrors };

    serverErrors.forEach((error) => {
      const key = error.property_path;
      const { message } = error;
      const validatorName = 'server';

      errors[serverErrorsToClientMap[key]] = [{
        message,
        validatorName,
      }];
    });

    return errors;
  }

  getBody() {
    return (
      <div>
        <ChartSidebarStep title="Chart info" hasError={this.hasChartInfoError()}>
          <div className="wethod-chart-sidebar__step--info">
            <div className="wethod-chart-sidebar__step-input">
              <InputValidator updateErrors={this.updateErrors}
                constraints={['required', 'maxLength:50']}>
                <TextField name="name"
                  label="Name"
                  helperText="Name briefly your new report chart"
                  value={this.state.config.name}
                  errorText={this.getErrorText('name')}
                  onChange={this.onInputChange} />
              </InputValidator>
            </div>
            <div className="wethod-chart-sidebar__step-input">
              <InputValidator updateErrors={this.updateErrors} constraints={['maxLength:150']}>
                <TextArea name="description"
                  label="Description"
                  value={this.state.config.description}
                  errorText={this.getErrorText('description')}
                  onChange={this.onInputChange} />
              </InputValidator>
            </div>
          </div>
        </ChartSidebarStep>
        <DataSegmentStep
          updateErrors={this.updateErrors}
          getErrors={this.getInputErrors}
          onChange={this.onFilterInputChange}
          filters={this.state.config.filters}
          handleReset={this.handleFiltersReset} />
        <ChartSidebarStep title="Type of chart">
          <div className="wethod-chart-sidebar__step--type">
            <RadioGroup name="type" value={this.state.config.type} onChange={this.onInputChange}>
              <BarTypeOption value="bar" />
              <StackedBarTypeOption value="stacked-bar" />
            </RadioGroup>
          </div>
        </ChartSidebarStep>
        <DimensionsStep hasError={this.hasChartDimensionsError()}
          updateErrors={this.updateErrors}
          getErrorText={this.getErrorText}
          chartType={this.state.config.type}
          chartDimensions={this.state.config.dimensions}
          onChange={this.onDimensionChange} />
      </div>
    );
  }

  /**
   * @return {boolean}
   */
  hasChartInfoError() {
    const nameErrors = this.getInputErrors('name');
    const descriptionErrors = this.getInputErrors('description');
    return nameErrors.length + descriptionErrors.length > 0;
  }

  hasChartDimensionsError() {
    const errorsAmount = this.state.config.dimensions.getKeys()
      .reduce((sum, key) => sum + this.getInputErrors(key).length, 0);

    return errorsAmount > 0;
  }

  /**
   *
   * @param {string} name input's name
   * @param {{validatorName:string, message:string}[]} errors
   */
  updateErrors(name, errors) {
    this.setState((state) => ({
      errors: {
        ...state.errors,
        [name]: errors,
      },
    }));
  }

  hasUnsavedChanges() {
    return !isEqual(this.state.config, this.props.config);
  }

  hasErrors() {
    const errorKeys = Object.keys(this.state.errors)
      .filter((key) => this.state.errors[key].length > 0);

    return errorKeys.length > 0;
  }

  canSave() {
    return !this.hasErrors();
  }

  render() {
    return (
      <Sidebar body={this.getBody()}
        className="wethod-chart-sidebar"
        title={this.props.title}
        onCancel={this.handleCancelClick}
        onClose={this.handleCancelClick}
        canSave={this.canSave()}
        hasUnsavedChanges={this.hasUnsavedChanges()}
        onSave={this.onSave}
        isSaving={this.props.isSaving} />
    );
  }
}

ChartSidebar.defaultProps = {
  isSaving: false,
  errors: [],
};

ChartSidebar.propTypes = {
  config: PropTypes.instanceOf(ChartConfig).isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string.isRequired,
    property_path: PropTypes.string.isRequired,
  })),
  title: PropTypes.string.isRequired,
};

module.exports = ChartSidebar;
