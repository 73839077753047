class ModalService {
  constructor() {
    this.listeners = [];
    this.currentModal = null;
  }

  /**
   * @param {JSX.Element} modalName
   */
  openModal(modalName) {
    this.currentModal = modalName;
    this.notifyListeners();
  }

  closeModal() {
    this.currentModal = null;
    this.notifyListeners();
  }

  subscribe(listener) {
    this.listeners.push(listener);
  }

  unsubscribe(listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  notifyListeners() {
    this.listeners.forEach((listener) => listener(this.currentModal));
  }
}

module.exports = new ModalService();
