const React = require('react');
const PropTypes = require('prop-types');
const ChartSidebarStep = require('./ChartSidebarStep.react');
const Dimension = require('./ChartSidebarDimension.react');
const ChartConfig = require('../../../../apps/core/modules/insights/revenues/models/ChartConfig');
const ChartConfigDimensions = require('../../../../apps/core/modules/insights/revenues/models/ChartConfigDimensions');
const DimensionSelectItem = require('./models/DimensionSelectItem');

const ChartSidebarDimensionsStep = ({
  chartType, updateErrors, getErrorText, chartDimensions,
  onChange, hasError,
}) => {
  const availableDimensions = [
    new DimensionSelectItem('revenues', 'Revenues'),
    new DimensionSelectItem('probability', 'Probability'),
    new DimensionSelectItem('client', 'Client'),
    new DimensionSelectItem('time', 'Time'),
  ];

  const getAxis = () => {
    switch (chartType) {
      case ChartConfig.TYPE_STACKED_BAR:
        return [{
          key: 'x',
          label: 'X Axis',
        }, {
          key: 'y',
          label: 'Y Axis',
        }, {
          key: 'color',
          label: 'Color',
        }];
      default:
        return [{
          key: 'x',
          label: 'X Axis',
        }, {
          key: 'y',
          label: 'Y Axis',
        }];
    }
  };

  const getDimensions = () => getAxis().map((axis) => (
    <Dimension availableDimensions={availableDimensions}
      axisLabel={axis.label}
      axisKey={axis.key}
      updateErrors={updateErrors}
      getError={getErrorText}
      dimension={chartDimensions[axis.key]}
      onChange={onChange} />
  ));

  return (
    <ChartSidebarStep title="Dimensions"
      hasError={hasError}>
      <div>
        {getDimensions()}
      </div>
    </ChartSidebarStep>
  );
};

ChartSidebarDimensionsStep.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartDimensions: PropTypes.instanceOf(ChartConfigDimensions).isRequired,
  updateErrors: PropTypes.func.isRequired,
  getErrorText: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

module.exports = ChartSidebarDimensionsStep;
