const constants = require('./constants');

class DataFreezingReducer {
  constructor(state) {
    this.state = {
      ...state,
      modal: null,
      waitingFor: [],
      warningsTotals: null,
      unarchivedProjectsData: {
        items: [],
        offset: 0,
        limit: 8,
        hasMorePages: false, // Projects are loaded after a segment is selected
        search: '',
        sort: 'asc',
        order: 'name',
        filters: {
          probability: '0,100',
          archived: 'false',
        },
      },
      outstandingProjectsData: {
        items: [],
        offset: 0,
        limit: 8,
        hasMorePages: false, // Projects are loaded after a segment is selected
        search: '',
        sort: 'asc',
        order: 'name',
        filters: {
          'min-prob': '1',
          'max-prob': '99',
        },
      },
      unapprovedBudgetsData: {
        items: [],
        offset: 0,
        limit: 8,
        hasMorePages: false, // Projects are loaded after a segment is selected
        search: '',
        sort: 'asc',
        order: 'name',
        filters: {
          probability: '0,100',
          'budget-status': 'draft,submitted',
        },
      },
      intercompanyActivityData: {
        items: [],
        filters: {},
      },
      alertsData: {
        items: [],
        offset: 0,
        limit: 8,
        hasMorePages: false, // Projects are loaded after a segment is selected
        search: '',
        sort: 'asc',
        order: 'name',
        filters: {},
      },
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SHOW_MODAL:
        return {
          ...state,
          modal: {
            key: action.key,
            data: action.data,
          },
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modal: null,
        };
      case constants.GET_WARNINGS_TOTAL_REQUEST:
        return {
          ...state,
          warningsTotals: null,
          waitingFor: state.waitingFor.concat('warnings-total'),
        };
      case constants.GET_WARNINGS_TOTAL_SUCCESS:
        return {
          ...state,
          warningsTotals: action.totals,
          waitingFor: state.waitingFor.filter((item) => item !== 'warnings-total'),
        };
      case constants.GET_WARNINGS_TOTAL_ERROR:
        return {
          ...state,
          warningsTotals: null,
          waitingFor: state.waitingFor.filter((item) => item !== 'warnings-total'),
        };
      case constants.GET_UNARCHIVED_PROJECTS_REQUEST:
        return {
          ...state,
          unarchivedProjectsData: {
            ...state.unarchivedProjectsData,
            items: action.offset ? state.unarchivedProjectsData.items : [],
            search: action.search,
            offset: action.offset,
            limit: action.limit,
            sort: action.sort,
            order: action.order,
            filters: action.filters,
          },
          waitingFor: state.waitingFor.concat('unarchived-projects'),
        };
      case constants.GET_UNARCHIVED_PROJECTS_SUCCESS:
        return {
          ...state,
          unarchivedProjectsData: {
            ...state.unarchivedProjectsData,
            items: state.unarchivedProjectsData.items.concat(action.projects),
            hasMorePages: action.projects.length >= state.unarchivedProjectsData.limit,
          },
          waitingFor: state.waitingFor.filter((item) => item !== 'unarchived-projects'),
        };
      case constants.GET_OUTSTANDING_PROJECTS_REQUEST:
        return {
          ...state,
          outstandingProjectsData: {
            ...state.outstandingProjectsData,
            items: action.offset ? state.outstandingProjectsData.items : [],
            search: action.search,
            offset: action.offset,
            limit: action.limit,
            sort: action.sort,
            order: action.order,
            filters: action.filters,
          },
          waitingFor: state.waitingFor.concat('outstanding-projects'),
        };
      case constants.GET_OUTSTANDING_PROJECTS_SUCCESS:
        return {
          ...state,
          outstandingProjectsData: {
            ...state.outstandingProjectsData,
            items: state.outstandingProjectsData.items.concat(action.projects),
            hasMorePages: action.projects.length >= state.outstandingProjectsData.limit,
          },
          waitingFor: state.waitingFor.filter((item) => item !== 'outstanding-projects'),
        };
      case constants.GET_UNAPPROVED_BUDGETS_REQUEST:
        return {
          ...state,
          unapprovedBudgetsData: {
            ...state.unapprovedBudgetsData,
            items: action.offset ? state.unapprovedBudgetsData.items : [],
            search: action.search,
            offset: action.offset,
            limit: action.limit,
            sort: action.sort,
            order: action.order,
            filters: action.filters,
          },
          waitingFor: state.waitingFor.concat('unapproved-budgets'),
        };
      case constants.GET_UNAPPROVED_BUDGETS_SUCCESS:
        return {
          ...state,
          unapprovedBudgetsData: {
            ...state.unapprovedBudgetsData,
            items: state.unapprovedBudgetsData.items.concat(action.projects),
            hasMorePages: action.projects.length >= state.unapprovedBudgetsData.limit,
          },
          waitingFor: state.waitingFor.filter((item) => item !== 'unapproved-budgets'),
        };
      case constants.GET_INTERCOMPANY_ACTIVITY_REQUEST:
        return {
          ...state,
          intercompanyActivityData: {
            ...state.intercompanyActivityData,
            items: [],
            filters: action.filters,
          },
          waitingFor: state.waitingFor.concat('intercompany-activity'),
        };
      case constants.GET_INTERCOMPANY_ACTIVITY_SUCCESS:
        return {
          ...state,
          intercompanyActivityData: {
            ...state.intercompanyActivityData,
            items: state.intercompanyActivityData.items.concat(action.activities),
          },
          waitingFor: state.waitingFor.filter((item) => item !== 'intercompany-activity'),
        };
      case constants.GET_ALERTS_REQUEST:
        return {
          ...state,
          alertsData: {
            ...state.alertsData,
            items: action.offset ? state.alertsData.items : [],
            search: action.search,
            offset: action.offset,
            limit: action.limit,
            sort: action.sort,
            order: action.order,
            filters: action.filters,
          },
          waitingFor: state.waitingFor.concat('alerts'),
        };
      case constants.GET_ALERTS_SUCCESS:
        return {
          ...state,
          alertsData: {
            ...state.alertsData,
            items: state.alertsData.items.concat(action.alerts),
            hasMorePages: action.alerts.length >= state.alertsData.limit,
          },
          waitingFor: state.waitingFor.filter((item) => item !== 'alerts'),
        };
      case constants.SELECT_SEGMENT:
        return {
          ...state,
          selectedSegment: state.segments
            .find((segment) => segment.status === action.segmentStatus),
          unarchivedProjectsData: {
            ...state.unarchivedProjectsData,
            items: [],
            hasMorePages: true,
            filters: {
              ...state.unarchivedProjectsData.filters,
              'min-end-date': state.segments
                .find((segment) => segment.status === action.segmentStatus)?.from
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).from}-01`
                : null,
              'max-end-date': state.segments
                .find((segment) => segment.status === action.segmentStatus)?.to
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).to}-01`
                : null,
            },
          },
          outstandingProjectsData: {
            ...state.outstandingProjectsData,
            items: [],
            hasMorePages: true,
            filters: {
              ...state.outstandingProjectsData.filters,
              'min-start-date': state.segments
                .find((segment) => segment.status === action.segmentStatus)?.from
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).from}-01`
                : null,
              'max-start-date': state.segments
                .find((segment) => segment.status === action.segmentStatus)?.to
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).to}-01`
                : null,
            },
          },
          unapprovedBudgetsData: {
            ...state.unapprovedBudgetsData,
            items: [],
            hasMorePages: true,
            filters: {
              ...state.unapprovedBudgetsData.filters,
              'min-end-date': state.segments
                .find((segment) => segment.status === action.segmentStatus)?.from
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).from}-01`
                : null,
              'max-end-date': state.segments
                .find((segment) => segment.status === action.segmentStatus)?.to
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).to}-01`
                : null,
            },
          },
          intercompanyActivityData: {
            ...state.intercompanyActivityData,
            filters: {
              ...state.intercompanyActivityData.filters,
              from: state.segments
                .find((segment) => segment.status === action.segmentStatus)?.from
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).from}-01`
                : null,
              to: state.segments
                .find((segment) => segment.status === action.segmentStatus)?.to
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).to}-01`
                : null,
            },
          },
          alertsData: {
            ...state.alertsData,
            items: [],
            hasMorePages: true,
            filters: {
              from: state.segments
                .find((segment) => segment.status === action.segmentStatus)?.from
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).from}-01`
                : null,
              to: state.segments
                .find((segment) => segment.status === action.segmentStatus)?.to
                ? `${state.segments.find((segment) => segment.status === action.segmentStatus).to}-01`
                : null,
            },
          },
        };
      case constants.UPDATE_SEGMENTS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('update-segments'),
        };
      case constants.UPDATE_SEGMENTS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((item) => item !== 'update-segments'),
          segments: action.segments,
          selectedSegment: null,
        };
      case constants.UPDATE_SEGMENTS_ERROR:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((item) => item !== 'update-segments'),
          selectedSegment: null,
        };
      default:
        return state;
    }
  }
}

module.exports = DataFreezingReducer;
