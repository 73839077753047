const ChartConfigDimension = require('../ChartConfigDimension');
const ChartConfigTimeDimensionGrouping = require('./ChartConfigTimeDimensionGrouping');

class ChartConfigTimeDimension extends ChartConfigDimension {
  /**
   * @param {ChartConfigTimeDimensionGrouping} grouping
   */
  constructor(grouping = new ChartConfigTimeDimensionGrouping()) {
    super(ChartConfigTimeDimension.KEY);
    this.grouping = grouping;
  }

  /**
   * @param data
   * @return {ChartConfigTimeDimension}
   */
  static fromJSON(data) {
    const grouping = ChartConfigTimeDimensionGrouping.fromJSON(data.grouping);
    return new ChartConfigTimeDimension(grouping);
  }
}

ChartConfigTimeDimension.KEY = 'time';

module.exports = ChartConfigTimeDimension;
