const React = require('react');
const PropTypes = require('prop-types');
const ChartSidebarStep = require('./ChartSidebarStep.react');

const ChartSidebarStepStatus = ({ type }) => {
  const baseClassName = 'wethod-chart-sidebar__step-status';
  return (
    <span className={`${baseClassName} ${baseClassName}--${type}`} />
  );
};

ChartSidebarStepStatus.propTypes = {
  type: PropTypes.oneOf([
    ChartSidebarStep.STATUS_COMPLETE,
    ChartSidebarStep.STATUS_ERROR]).isRequired,
};

module.exports = ChartSidebarStepStatus;
