module.exports = class ChartConfigProbabilityDimensionGroupingValue {
  /**
   * @param {string} name
   * @param {number[]} values
   */
  constructor(name, values) {
    this.name = name;
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigProbabilityDimensionGroupingValue}
   */
  static fromJSON(data) {
    return new ChartConfigProbabilityDimensionGroupingValue(data.name, data.values);
  }
};
