/**
 * @abstract
 * @type {ChartConfigDimension}
 */
module.exports = class ChartConfigDimension {
  /**
   * @param {string} key
   */
  constructor(key) {
    this.key = key;
  }
};
