const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../sidebar/SidebarAccordion/SidebarAccordion.react');
const Status = require('./ChartSidebarStepStatus.react');
const T = require('../../Typography/Typography.react');

const ChartSidebarStep = ({ children, title, hasError, expanded, summary, className }) => {
  const label = <T size={T.SIZES.PX14} weight={T.WEIGHTS.SEMIBOLD}>{title}</T>;
  const statusType = hasError ? ChartSidebarStep.STATUS_ERROR : ChartSidebarStep.STATUS_COMPLETE;
  return (
    <Accordion actions={<Status type={statusType} />}
      label={label}
      expanded={expanded}
      summary={summary}
      className={className}>
      {children}
    </Accordion>
  );
};

ChartSidebarStep.STATUS_COMPLETE = 'complete';
ChartSidebarStep.STATUS_ERROR = 'error';

ChartSidebarStep.defaultProps = {
  expanded: false,
  summary: null,
  hasError: false,
  className: '',
};

ChartSidebarStep.propTypes = {
  title: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  expanded: PropTypes.bool,
  children: PropTypes.node.isRequired,
  summary: PropTypes.node,
  className: PropTypes.string,
};

module.exports = ChartSidebarStep;
