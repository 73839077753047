const React = require('react');
const PropTypes = require('prop-types');
const T = require('../../Typography/Typography.react');
const Loader = require('./ChartLoader.react');
const ShowIf = require('../../ShowIf/ShowIf.react');

require('./style.scss');
const Button = require('../../Button/RoundedButton.react');

/**
 * Container for a chart.
 *
 * @param {JSX.Element} children Element to render as chart body
 * @param onEditChartClick Function to call when edit chart is clicked
 * @param {bool} canEdit If user can edit chart or not
 * @param {string} title Chart title
 * @param {string} description Chart description
 * @param {boolean} isLoading If chart points are loading
 * @return {JSX.Element}
 * @constructor
 */
const Chart = ({
  children, onEditChartClick, canEdit, title = '', description = '',
  isLoading = false,
}) => {
  const body = isLoading ? <Loader /> : children;

  return (
    <div className="wethod-chart">
      <div className="wethod-chart__header">
        <div className="wethod-chart__header-info">
          <div className="wethod-chart__name">
            <T weight={T.WEIGHTS.SEMIBOLD} size={T.SIZES.PX16}>
              {title}
            </T>
          </div>
          <div className="wethod-chart__description">
            <T size={T.SIZES.PX14}>
              {description}
            </T>
          </div>
        </div>
        <div className="wethod-chart__header-actions">
          <ShowIf condition={canEdit}>
            <Button onClick={onEditChartClick}>Edit chart</Button>
          </ShowIf>
        </div>
      </div>
      <div className="wethod-chart__body">
        {body}
      </div>
    </div>
  );
};

Chart.defaultProps = {
  title: '',
  description: '',
  isLoading: false,
  onEditChartClick: null,
  canEdit: false,
};

Chart.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  onEditChartClick: PropTypes.func,
  canEdit: PropTypes.bool,
};

module.exports = Chart;
