const ChartConfigDimensions = require('./ChartConfigDimensions');

module.exports = class BarChartConfigDimensions extends ChartConfigDimensions {
  /**
   * @param {ChartConfigDimension} x
   * @param {ChartConfigDimension} y
   * @param {ChartConfigDimension} color
   */
  constructor(x, y, color = null) {
    super();
    this.x = x;
    this.y = y;
    this.color = color;
  }

  /**
   * Return list of dimension keys.
   * @return {string[]}
   */
  getKeys() {
    return Object.keys(this);
  }

  /**
   * Return attributes to serialize when calling JSON.stringify on this object.
   */
  toJSON() {
    return {
      x: this.x,
      y: this.y,
      color: this.color !== null ? this.color : undefined,
    };
  }

  /**
   * Return a copy of this instance.
   * @return {BarChartConfigDimensions}
   */
  clone() {
    return new BarChartConfigDimensions(this.x, this.y, this.color);
  }

  /**
   * @param data
   * @return {BarChartConfigDimensions}
   */
  static fromJSON(data) {
    const x = this.dimensionFromJSON(data.x);
    const y = this.dimensionFromJSON(data.y);
    const color = data.color ? this.dimensionFromJSON(data.color) : null;
    return new BarChartConfigDimensions(x, y, color);
  }
};
