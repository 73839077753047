const constants = require('./constants');
const modals = require('./modals');
const DataFreezingModel = require('../../../../models/DataFreezingSegment');
const ItemModel = require('../../../../models/PipelineItem');
const IntercompanyActivity = require('../../../../models/IntercompanyActivity');
const AlertModel = require('../../../../models/Alert');

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.closeModal = closeModal;

const showModal = (key, data) => ({
  type: constants.SHOW_MODAL,
  key,
  data,
});
module.exports.showModal = showModal;

module.exports.showErrorModal = (message) => showModal(
  modals.ERROR, { message },
);

module.exports.showReviewModal = () => showModal(
  modals.START_REVIEW,
);

module.exports.showEditPeriodModal = (segment) => showModal(
  modals.EDIT_PERIOD,
  { segment },
);

module.exports.showEditStatusModal = (segment) => showModal(
  modals.EDIT_STATUS,
  { segment },
);

module.exports.showIgnoreAlertsModal = (updatedSegments) => showModal(
  modals.IGNORE_ALERTS,
  { updatedSegments },
);

module.exports.showConfirmPauseModal = (updatedSegments) => showModal(
  modals.CONFIRM_PAUSE,
  { updatedSegments },
);

module.exports.showConfirmFreezeModal = (updatedSegments) => showModal(
  modals.CONFIRM_FREEZE,
  { updatedSegments },
);

const getWarningsTotalRequest = () => ({
  type: constants.GET_WARNINGS_TOTAL_REQUEST,
});

const getWarningsTotalSuccess = (totals) => ({
  type: constants.GET_WARNINGS_TOTAL_SUCCESS,
  totals,
});

const getWarningsTotalError = (error) => ({
  type: constants.GET_WARNINGS_TOTAL_ERROR,
  error,
});

module.exports.getWarningsTotal = (from, to) => (dispatch) => {
  dispatch(getWarningsTotalRequest());

  const request = DataFreezingModel.getWarningsTotal(from, to);
  $.when(request)
    .done((warnings) => dispatch(getWarningsTotalSuccess(warnings)))
    .fail((error) => dispatch(getWarningsTotalError(error)));
};

const getUnarchivedProjectsRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_UNARCHIVED_PROJECTS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getUnarchivedProjectsSuccess = (projects) => ({
  type: constants.GET_UNARCHIVED_PROJECTS_SUCCESS,
  projects,
});

module.exports.getUnarchivedProjects = (
  offset, limit, search, order, sort, filters,
) => (dispatch) => {
  dispatch(getUnarchivedProjectsRequest(offset, limit, search, order, sort, filters));

  const options = {
    filters: {
      ...filters,
      search,
    },
    offset,
    limit,
    orderBy: order,
    sort,
    bu: 'all',
  };

  const getListRequest = ItemModel.getAll(options);
  $.when(getListRequest)
    .done((projects) => dispatch(getUnarchivedProjectsSuccess(projects.toJSON() || [])));
};

const getOutstandingProjectsRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_OUTSTANDING_PROJECTS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getOutstandingProjectsSuccess = (projects) => ({
  type: constants.GET_OUTSTANDING_PROJECTS_SUCCESS,
  projects,
});

module.exports.getOutstandingProjects = (
  offset, limit, search, order, sort, filters,
) => (dispatch) => {
  dispatch(getOutstandingProjectsRequest(offset, limit, search, order, sort, filters));

  const options = {
    filters: {
      ...filters,
      search,
    },
    offset,
    limit,
    orderBy: order,
    sort,
    bu: 'all',
  };

  const getListRequest = ItemModel.getAll(options);
  $.when(getListRequest)
    .done((projects) => dispatch(getOutstandingProjectsSuccess(projects.toJSON() || [])));
};

const getUnapprovedBudgetsRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_UNAPPROVED_BUDGETS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getUnapprovedBudgetsSuccess = (projects) => ({
  type: constants.GET_UNAPPROVED_BUDGETS_SUCCESS,
  projects,
});

module.exports.getUnapprovedBudgets = (
  offset, limit, search, order, sort, filters,
) => (dispatch) => {
  dispatch(getUnapprovedBudgetsRequest(offset, limit, search, order, sort, filters));

  const options = {
    filters: {
      ...filters,
      search,
    },
    offset,
    limit,
    orderBy: order,
    sort,
    bu: 'all',
  };

  const getListRequest = ItemModel.getAll(options);
  $.when(getListRequest)
    .done((projects) => dispatch(getUnapprovedBudgetsSuccess(projects.toJSON() || [])));
};

const getIntercompanyActivityRequest = (filters) => ({
  type: constants.GET_INTERCOMPANY_ACTIVITY_REQUEST,
  filters,
});
const getIntercompanyActivitySuccess = (activities) => ({
  type: constants.GET_INTERCOMPANY_ACTIVITY_SUCCESS,
  activities,
});
module.exports.getIntercompanyActivities = (
  filters,
) => (dispatch) => {
  dispatch(getIntercompanyActivityRequest(filters));

  const { from, to } = filters;

  const getListRequest = IntercompanyActivity.list(from, to);
  $.when(getListRequest)
    .done((activities) => dispatch(getIntercompanyActivitySuccess(activities || [])));
};

const getAlertsRequest = (offset, limit, search, order, sort, filters) => ({
  type: constants.GET_ALERTS_REQUEST,
  offset,
  limit,
  search,
  order,
  sort,
  filters,
});

const getAlertsSuccess = (alerts) => ({
  type: constants.GET_ALERTS_SUCCESS,
  alerts,
});

module.exports.getAlerts = (
  offset, limit, search, order, sort, filters,
) => (dispatch) => {
  dispatch(getAlertsRequest(offset, limit, search, order, sort, filters));

  const options = {
    search,
    offset,
    limit,
    orderBy: order,
    sort,
  };

  const getListRequest = AlertModel.getProjectAlerts(options, filters);
  $.when(getListRequest)
    .done((alerts) => dispatch(getAlertsSuccess(alerts || [])));
};

module.exports.selectSegment = (segmentStatus) => ({
  type: constants.SELECT_SEGMENT,
  segmentStatus,
});

const updateSegmentsRequest = () => ({
  type: constants.UPDATE_SEGMENTS_REQUEST,
});

const updateSegmentsSuccess = (segments) => ({
  type: constants.UPDATE_SEGMENTS_SUCCESS,
  segments,
});

const updateSegmentsError = (error) => ({
  type: constants.UPDATE_SEGMENTS_ERROR,
  error,
});

module.exports.updateSegments = (segments) => (dispatch) => {
  dispatch(updateSegmentsRequest());

  $.when(DataFreezingModel.updateSegments(segments)
    .done((updatedSegments) => {
      dispatch(updateSegmentsSuccess(updatedSegments));
      dispatch(getWarningsTotalSuccess(null)); // Reset warnings total
    })
    .fail((error) => dispatch(updateSegmentsError(error)))
    .always(() => dispatch(closeModal())));
};
