const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('../../Accordion/Accordion.react');

require('./style.scss');

const SidebarAccordion = ({
  children, expanded, label, className, actions, summary,
  onChange,
}) => {
  const getClassName = () => `wethod-sidebar__accordion ${className}`;

  return (
    <Accordion label={label}
      actions={actions}
      showStatusIcon
      expanded={expanded}
      summary={summary}
      onChange={onChange}
      className={getClassName()}>
      {children}
    </Accordion>
  );
};

SidebarAccordion.defaultProps = {
  expanded: false,
  label: null,
  actions: null,
  summary: null,
  className: null,
  onChange: null,
};

SidebarAccordion.propTypes = {
  /** Node to use as content * */
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  label: PropTypes.node,
  className: PropTypes.string,
  /** Node containing actions, placed on the label left * */
  actions: PropTypes.node,
  /** Node containing a summary of accordion content, placed between label and status icon * */
  summary: PropTypes.node,
  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param {boolean} expanded True if accordion is expaned
   * * */
  onChange: PropTypes.func,
};

module.exports = SidebarAccordion;
