const ChartConfigFilterClient = require('./ChartConfigFilterClient');
const ChartConfigFilter = require('./ChartConfigFilter');

module.exports = class ChartConfigFilterClientNotIncluded extends ChartConfigFilterClient {
  /**
   * @param {string[]} values
   */
  constructor(values) {
    super(ChartConfigFilter.TYPE_NOT_IN);
    this.values = values;
  }

  /**
   * @param data
   * @return {ChartConfigFilterClientNotIncluded}
   */
  static fromJSON(data) {
    return new ChartConfigFilterClientNotIncluded(data.values);
  }
};
