const React = require('react');
const Tab = require('./InsightLevelChartTab.react');
// eslint-disable-next-line no-unused-vars
const Chart = require('../models/Chart');
const ShowIf = require('../../../../../../common/react/ShowIf/ShowIf.react');

/**
 * Shows a list of chart titles and allows selecting one.
 * @param {Chart[]} charts
 * @param {?number} selectedId ID of the currently selected chart
 * @param onClick
 * @param onDeleteClick
 * @param withTableView
 * @return {JSX.Element}
 * @constructor
 */
const InsightLevelChartPicker = ({
  charts, selectedId, onClick, onDeleteChart, userHasChartEditPermissions,
  withTableView = false,
}) => {
  const getTabs = () => charts
    .map((chart) => {
      const canDeleteChart = userHasChartEditPermissions && !chart.isDefault;

      return (
        <Tab key={chart.id}
          id={chart.id}
          title={chart.config.name}
          onClick={onClick}
          selected={selectedId === chart.id}
          onDeleteChart={onDeleteChart}
          canDelete={canDeleteChart} />
      );
    });

  return (
    <ul className="wethod-insight-level__chart-picker" role="tablist">
      <ShowIf condition={withTableView}>
        <Tab title="Table view" onClick={onClick} id="table-view" selected={selectedId === 'table-view'} canDelete={false} />
      </ShowIf>
      {getTabs()}
    </ul>
  );
};

module.exports = InsightLevelChartPicker;
