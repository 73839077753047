const React = require('react');
const PropTypes = require('prop-types');
const Radio = require('../../inputs/RadioGroup/Radio.react');
const Label = require('./ChartSidebarTypeOptionLabel.react');

const ChartSidebarTypeOption = ({ value, name, onClick, id, title, subtitle, icon, checked }) => {
  const label = <Label title={title} subtitle={subtitle} icon={icon} />;

  const getClassName = () => {
    let className = 'wethod-chart-sidebar__type-option';
    if (checked) {
      className = `${className} ${className}--checked`;
    }
    return className;
  };

  return (
    <Radio value={value}
      label={label}
      name={name}
      onClick={onClick}
      id={id}
      className={getClassName()}
      checked={checked} />
  );
};

ChartSidebarTypeOption.defaultProps = {
  id: null,
  onClick: null,
};

ChartSidebarTypeOption.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

module.exports = ChartSidebarTypeOption;
