const React = require('react');
const PropTypes = require('prop-types');
const Options = require('./ChartSidebarDimensionOptions.react');
const RadioGroup = require('../../../inputs/RadioGroup/RadioGroup.react');
const Radio = require('../../../inputs/RadioGroup/Radio.react');
const ChartConfigTimeDimensionGrouping = require('../../../../../apps/core/modules/insights/revenues/models/ChartConfigDimension/ChartConfigTimeDimension/ChartConfigTimeDimensionGrouping');

const ChartSidebarDimensionTimeOptions = ({ value, onChange }) => (
  <Options>
    <RadioGroup name="time-dimension-options" onChange={onChange} value={value}>
      <Radio value={ChartConfigTimeDimensionGrouping.VALUE_YEAR} label="Years" />
      <Radio value={ChartConfigTimeDimensionGrouping.VALUE_MONTH} label="Months" />
      <Radio value={ChartConfigTimeDimensionGrouping.VALUE_QUARTER} label="Quarters" />
    </RadioGroup>
  </Options>
);

ChartSidebarDimensionTimeOptions.defaultProps = {
  value: null,
};

ChartSidebarDimensionTimeOptions.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

module.exports = ChartSidebarDimensionTimeOptions;
