const { connect } = require('react-redux');
const Component = require('../../../components/dashboard/unapproved-budgets/UnapprovedBudgets.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.includes('unapproved-budgets'),
  items: state.unapprovedBudgetsData.items,
  search: state.unapprovedBudgetsData.search,
  filters: state.unapprovedBudgetsData.filters,
  offset: state.unapprovedBudgetsData.offset,
  limit: state.unapprovedBudgetsData.limit,
  hasMorePages: state.unapprovedBudgetsData.hasMorePages,
  sort: state.unapprovedBudgetsData.sort,
  order: state.unapprovedBudgetsData.order,
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  getItems:
    (offset, limit, search, order, sort, filters) => dispatch(actions
      .getUnapprovedBudgets(offset, limit, search, order, sort, filters)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
