const React = require('react');
const isEqual = require('react-fast-compare');
const PropTypes = require('prop-types');
const ChartModel = require('../../../../../../models/Chart');
const ChartBuilder = require('../../../../../../common/react/charts/ChartBuilder/ChartBuilder.react');
const BarChartPoint = require('../../../../../../common/react/charts/BarChart/models/BarChartPoint');
const BuilderService = require('../services/BuilderService');
const Chart = require('../models/Chart');
const ChartConfigFilter = require('../models/ChartConfigFilter/ChartConfigFilter');
const Error = require('./InsightLevelChartEmptyState/InsightLevelChartEmptyState.react');

class InsightLevelChartContainer extends React.Component {
  static parsePoint(point) {
    return new BarChartPoint(point.x, point.y, point.color);
  }

  constructor(props) {
    super(props);

    /**
     * @type {{isLoading: boolean, selectedPoint: ?BarChartPoint, points: BarChartPoint[]}}
     */
    this.state = {
      points: [],
      isLoading: true,
      selectedPoint: null,
      error: null,
    };

    this.onPointClick = this.onPointClick.bind(this);
  }

  componentDidMount() {
    this.loadPoints();
  }

  componentDidUpdate(prevProps, prevState) {
    const chartChanged = !isEqual(this.props.chart, prevProps.chart);
    const filtersChanged = this.props.filters !== prevProps.filters;
    const selectedPointChanged = this.state.selectedPoint?.id !== prevState.selectedPoint?.id;
    if (chartChanged || filtersChanged) {
      this.loadPoints();
    }
    if (selectedPointChanged) {
      if (this.state.selectedPoint) {
        const filters = BuilderService.buildFilterByPoint(this.state.selectedPoint,
          this.props.chart, this.state.points);
        this.props.updateChildrenFilters(filters);
      } else {
        this.props.updateChildrenFilters([]);
      }
    }
  }

  /**
   * @param {BarChartPoint} point
   */
  onPointClick(point) {
    if (point?.id === this.state.selectedPoint?.id) {
      // Clicking on a currently selected point removes the selection
      this.updateSelectedPoint(null);
    } else {
      this.updateSelectedPoint(point);
    }
  }

  getContent() {
    switch (this.state.error) {
      case InsightLevelChartContainer.ERROR_UNKNOWN:
        return (
          <Error message="Something went wrong with this chart"
            chartTitle={this.props.chart.config.name}
            chartDescription={this.props.chart.config.description} />
        );
      case InsightLevelChartContainer.ERROR_EMPTY_POINTS:
        return (
          <Error message="There's no data for this chart"
            chartTitle={this.props.chart.config.name}
            chartDescription={this.props.chart.config.description} />
        );
      default:
        return (
          <ChartBuilder onEditChartClick={this.props.onEditChartClick}
            canEdit={this.canEditChart()}
            onPointClick={this.onPointClick}
            config={this.props.chart.config}
            points={this.state.points}
            isLoading={this.state.isLoading}
            selectedPoint={this.state.selectedPoint} />
        );
    }
  }

  /**
   * @param {BarChartPoint} point
   */
  updateSelectedPoint(point) {
    this.setState({ selectedPoint: point });
  }

  loadPoints() {
    this.setState({ isLoading: true });
    const filters = this.props.filters ? { filters: JSON.stringify(this.props.filters) }
      : undefined;

    ChartModel
      .getPoints(this.props.chart.id, filters)
      .done((points) => this.setState({
        points: points.map(InsightLevelChartContainer.parsePoint),
        isLoading: false,
        error: points.length === 0 ? InsightLevelChartContainer.ERROR_EMPTY_POINTS : null,
      }))
      .fail(() => this.setState({ error: InsightLevelChartContainer.ERROR_UNKNOWN }));
  }

  canEditChart() {
    return this.props.userHasChartEditPermissions && !this.props.chart.isDefault;
  }

  render() {
    return (
      <div className="wethod-insight-level__chart-container">
        {this.getContent()}
      </div>
    );
  }
}

InsightLevelChartContainer.ERROR_UNKNOWN = 'error-unknown';
InsightLevelChartContainer.ERROR_EMPTY_POINTS = 'error-empty-points';

InsightLevelChartContainer.defaultProps = {
  filters: [],
  onEditChartClick: null,
  userHasChartEditPermissions: false,
};

InsightLevelChartContainer.propTypes = {
  chart: PropTypes.instanceOf(Chart).isRequired,
  updateChildrenFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(ChartConfigFilter),
  onEditChartClick: PropTypes.func,
  userHasChartEditPermissions: PropTypes.bool,
};

module.exports = InsightLevelChartContainer;
