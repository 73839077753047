const React = require('react');
const modalService = require('./services/ModalService');

module.exports = class ModalManager extends React.Component {
  constructor(props) {
    super(props);

    /**
     * @type {{currentModal: ?JSX.Element}}
     */
    this.state = {
      currentModal: null,
    };

    this.handleModalChange = this.handleModalChange.bind(this);
  }

  componentDidMount() {
    modalService.subscribe(this.handleModalChange);
  }

  componentWillUnmount() {
    modalService.unsubscribe(this.handleModalChange);
  }

  handleModalChange(currentModal) {
    this.setState({ currentModal });
  }

  render() {
    if (this.state.currentModal) {
      return React.cloneElement(this.state.currentModal, {
        onClose: modalService.closeModal.bind(modalService),
      });
    }
    return null;
  }
};
