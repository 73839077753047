class SidebarService {
  constructor() {
    this.listeners = [];
    this.current = null;
  }

  /**
   * @param {JSX.Element} sidebar
   */
  openSidebar(sidebar) {
    this.current = sidebar;
    this.notifyListeners();
  }

  closeSidebar() {
    this.current = null;
    this.notifyListeners();
  }

  subscribe(listener) {
    this.listeners.push(listener);
  }

  unsubscribe(listener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  notifyListeners() {
    this.listeners.forEach((listener) => listener(this.current));
  }
}

module.exports = new SidebarService();
