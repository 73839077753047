const React = require('react');
const PropTypes = require('prop-types');
const Actions = require('./AdvancedSearchBodyActions.react');

const AdvancedSearchBody = ({
  totalActive, children, onSearch, onReset, resumeTitle,
  highlightResume,
}) => (
  <div>
    <Actions totalActive={totalActive}
      onReset={onReset}
      onSearch={onSearch}
      highlight={highlightResume}
      title={resumeTitle} />
    {children}
  </div>
);

AdvancedSearchBody.propTypes = {
  totalActive: PropTypes.number,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  children: (props, propName, componentName) => {
    const prop = props[propName];

    let error = null;

    // Required check
    if (!prop) {
      return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${prop}\`.`);
    }

    React.Children.forEach(prop, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.name === undefined || child.props.name === null) {
          // 'Name' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
        if (child.props.label === undefined || child.props.label === null) {
          // 'Label' prop is required in every children
          error = new Error(`\`${componentName}\` children should be have \`name\` prop, but its value is \`${child.props.name}\`.`);
        }
      } else {
        // Type check
        error = new Error(`Invalid prop \`children\` of type \`${typeof child}\` supplied to \`${componentName}\`, children should be valid elements.`);
      }
    });
    return error;
  }, // isRequired
  highlightResume: PropTypes.bool,
  resumeTitle: PropTypes.string,
};

AdvancedSearchBody.defaultProps = {
  totalActive: 0,
  onSearch: undefined,
  onReset: undefined,
  children: undefined,
  highlightResume: undefined,
  resumeTitle: undefined,
};

module.exports = AdvancedSearchBody;
