const React = require('react');
const PropTypes = require('prop-types');
const T = require('../../Typography/Typography.react');

const ChartSidebarTypeOptionLabel = ({ title, subtitle, icon }) => (
  <div className="wethod-chart-sidebar__type-option-label">
    <div className="wethod-chart-sidebar__type-option-label-description">
      <div className="wethod-chart-sidebar__type-option-label-title">
        <T size={T.SIZES.PX14}>{title}</T>
      </div>
      <div className="wethod-chart-sidebar__type-option-label-subtitle">
        <T size={T.SIZES.PX12} color={T.COLORS.NOBEL_GREY}>{subtitle}</T>
      </div>
    </div>
    <div className="wethod-chart-sidebar__type-option-label-icon">
      {icon}
    </div>
  </div>
);

ChartSidebarTypeOptionLabel.defaultProps = {
  subtitle: '',
  icon: null,
};

ChartSidebarTypeOptionLabel.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.node,
};

module.exports = ChartSidebarTypeOptionLabel;
