const ChartConfigClientDimensionGrouping = require('./ChartConfigClientDimensionGrouping');

class ChartConfigClientDimensionDistinctGrouping extends ChartConfigClientDimensionGrouping {
  constructor() {
    super(ChartConfigClientDimensionDistinctGrouping.TYPE);
  }

  /**
   * @return {ChartConfigClientDimensionDistinctGrouping}
   */
  static fromJSON(data) {
    return new ChartConfigClientDimensionDistinctGrouping(data);
  }

  // eslint-disable-next-line class-methods-use-this
  isGrouped() {
    return false;
  }
}

ChartConfigClientDimensionDistinctGrouping.TYPE = 'distinct';

module.exports = ChartConfigClientDimensionDistinctGrouping;
