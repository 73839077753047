module.exports = class DimensionSelectItem {
  /**
   * @param {string} key
   * @param {string} label
   */
  constructor(key, label) {
    this.key = key;
    this.label = label;
  }
};
