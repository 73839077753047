const request = require('./Request');
const HTTPService = require('../services/HTTPService');

const Chart = {
  list(filters = {}) {
    let url = '/charts';
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }

    return request({
      method: 'get',
      url,
    });
  },
  getPoints(chartId, filters = {}) {
    let url = `/charts/${chartId}/data`;
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }
    return request({
      method: 'get',
      url,
    });
  },
  getTableData(chartId, filters = {}) {
    let url = `/charts/${chartId}/table-data`;
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }
    return request({
      method: 'get',
      url,
    });
  },
  getTableTotals(chartId, filters = {}) {
    let url = `/charts/${chartId}/table-totals`;
    const queryString = HTTPService.buildQueryString(filters);
    if (queryString) {
      url += `?${queryString}`;
    }
    return request({
      method: 'get',
      url,
    });
  },
  create(data) {
    return request({
      method: 'post',
      url: '/charts',
      data,
    });
  },
  update(id, data) {
    return request({
      method: 'patch',
      url: `/charts/${id}`,
      data,
    });
  },
  delete(id) {
    return request({
      method: 'delete',
      url: `/charts/${id}`,
    });
  },
};

module.exports = Chart;
