const React = require('react');
const PropTypes = require('prop-types');
const T = require('../../Typography/Typography.react');
const Row = require('./ChartLegendItem.react');
const ChartLegendItem = require('./models/ChartLegendItem');

require('./style.scss');

/**
 * Legend related to a Chart.
 * @param {ChartLegendItem[]} items
 * @param {string} title
 * @return {JSX.Element}
 * @constructor
 */
const ChartLegend = ({ title, items }) => {
  const rows = items.map((item) => <Row key={item.label} color={item.color} label={item.label} />);

  return (
    <div className="wethod-chart-legend">
      <div className="wethod-chart-legend-title">
        <T size={T.SIZES.PX12}
          weight={T.WEIGHTS.SEMIBOLD}
          color={T.COLORS.NOBEL_GREY}
          uppercase>{title}
        </T>
      </div>
      <ul className="wethod-chart-legend-items">
        {rows}
      </ul>
    </div>
  );
};

ChartLegend.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(ChartLegendItem).isRequired,
};

module.exports = ChartLegend;
