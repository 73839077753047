const React = require('react');
const ModalContent = require('../../../../../../../common/react/Modal/ModalContent.react');
const ModalBody = require('../../../../../../../common/react/Modal/ModalBody.react');
const Footer = require('../../../../../../../common/react/Modal/ModalFooter.react');
const FooterAction = require('../../../../../../../common/react/Modal/ModalFooterAction.react');
const Modal = require('../../../../../../../common/react/Modal/Modal.react');
const ChartModel = require('../../../../../../../models/Chart');
const T = require('../../../../../../../common/react/Typography/Typography.react');

module.exports = class DeleteChartModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isWaiting: false };

    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  onDelete() {
    this.setState({ isWaiting: false });
    this.props.onDelete();
    this.props.onClose();
  }

  onDeleteClick() {
    this.setState({ isWaiting: true });
    ChartModel
      .delete(this.props.chartId)
      .done(this.onDelete.bind(this));
  }

  getChartName() {
    return this.props.chartName;
  }

  getFeedback() {
    return this.isDeleting() ? 'Deleting...' : '';
  }

  isDeleting() {
    return this.state.isWaiting;
  }

  render() {
    return (
      <Modal title="Delete chart" onClose={this.props.onClose}>
        <ModalContent>
          <ModalBody>
            <T>{`Are you sure you want to delete “${this.getChartName()}”?`}</T>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction
              onClick={this.props.onClose}
              disabled={this.isDeleting()}>Cancel
            </FooterAction>
            <FooterAction onClick={this.onDeleteClick}
              disabled={this.isDeleting()}>Yes
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
};
