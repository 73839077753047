const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const BarChartSeries = require('./models/BarChartSeries');
const ColorService = require('./services/ColorService');
const ChartLegendItem = require('../ChartLegend/models/ChartLegendItem');
const BarChartPoint = require('./models/BarChartPoint');
const ChartConfig = require('../../../../apps/core/modules/insights/revenues/models/ChartConfig');
const MathService = require('../../../../services/MathService');
const FormatService = require('../../../../services/FormatService');

class BarChartPlot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
    };

    this.state.series = this.getSeries();

    this.ref = null;
    this.highchartsChart = null;

    this.setRef = this.setRef.bind(this);

    this.onPointClick = this.onPointClick.bind(this);
  }

  componentDidMount() {
    this.mountChart();
  }

  componentDidUpdate(prevProps, prevState) {
    const changedPoints = this.props.points !== prevProps.points;
    const changedSeries = this.state.series !== prevState.series;
    const changedSelectedPoint = this.props.selectedPoint?.id !== prevProps.selectedPoint?.id;
    if (changedPoints) {
      this.updateSeries();
      this.mountChart();
    } else if (changedSeries) {
      this.mountChart();
    } else if (changedSelectedPoint) {
      if (this.props.selectedPoint) {
        const highchartsPoint = this.highchartsChart.get(this.props.selectedPoint.id);
        this.highlightSelectedPoint(highchartsPoint);
      } else {
        this.removePointsHighlight();
      }
    }
  }

  onPointClick(e) {
    const { id } = e.point;
    const barChartPoint = this.props.points.find((point) => point.id === id);

    this.props.onPointClick(barChartPoint);
  }

  getChartOptions() {
    // Needed to access component context from callback passed to Highcharts config
    const componentThis = this;

    return {
      chart: {
        height: 490,
        type: 'column',
        style: {
          fontFamily: 'Rubik',
        },
      },

      credits: {
        enabled: false,
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        categories: this.getCategories(),
        title: {
          text: this.props.xAxisLabel,
          style: {
            color: '#777',
            fontSize: '12px',
          },
          margin: 20,
        },
        gridLineWidth: 0,
        lineWidth: 0,
        labels: {
          formatter() {
            return componentThis.getFormattedAxisLabel('x', this.value);
          },
        },
      },

      yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
          text: this.props.yAxisLabel,
          style: {
            color: '#777',
            fontSize: '12px',
          },
          margin: 20,
        },
        gridLineWidth: 0,
        lineWidth: 0,
        stackLabels: {
          enabled: true,
          formatter() {
            return componentThis.getFormattedAxisLabel('y', this.total);
          },
        },
        labels: {
          formatter() {
            return componentThis.getFormattedAxisLabel('y', this.value);
          },
        },
      },

      tooltip: {
        enabled: this.isStackedType(),
        formatter() {
          const seriesName = this.series.name;
          return componentThis.getFormattedTooltip(this.key, this.y, seriesName);
        },
      },

      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          cursor: 'pointer',
          groupPadding: 0,
          events: {
            click: this.onPointClick,
          },
        },
      },

      series: this.state.series,
    };
  }

  /**
   * Return string to use as tooltip message for a point.
   * @see https://api.highcharts.com/highcharts/tooltip.formatter
   * @param {string} x point's value for the x dimension
   * @param {string} y point's value for the y dimension
   * @param {string} color point's value for the color dimension
   * @return {string}
   */
  getFormattedTooltip(x, y, color) {
    const formattedX = this.getFormattedAxisLabel('x', x);
    const formattedY = this.getFormattedAxisLabel('y', y);
    const formattedColor = this.getFormattedAxisLabel('color', color);
    if (this.isStackedType()) {
      return `<b>${formattedX}</b><br/>`
        + `• ${formattedColor}: ${formattedY}<br/>`;
    }
    return `<b>${formattedX}</b><br/>${formattedY}<br/>`;
  }

  getFormattedAxisLabel(axis, value) {
    const axisTypeMap = {
      x: this.props.xAxisType,
      y: this.props.yAxisType,
    };
    const type = axisTypeMap[axis];
    switch (type) {
      case 'month':
        return moment(value).format('MMM YYYY');
      case 'revenues': {
        const roundedValue = MathService.round(value);
        return FormatService.formatDecimalNumber(roundedValue, 1);
      }

      default:
        return value;
    }
  }

  /**
   *
   * @return {string[]}
   */
  getCategories() {
    // List of x axis values
    const categories = [];

    this.props.points.forEach((point) => {
      const category = point.x;
      if (!categories.includes(category)) {
        categories.push(category);
      }
    });

    return categories;
  }

  /**
   * @return {BarChartSeries[]}
   */
  getSeries() {
    // List of x axis values
    const categories = this.getCategories();
    // Map of BarChartPoint grouped by series they belong to
    const pointsBySeries = {};
    this.props.points.forEach((point) => {
      const pointSeries = point.color;
      if (pointsBySeries[pointSeries]) {
        pointsBySeries[pointSeries].push(point);
      } else {
        pointsBySeries[pointSeries] = [point];
      }
    });
    // List of series colors: one different color per series
    const seriesColors = ColorService.getColors(Object.entries(pointsBySeries).length);
    const series = [];
    for (let i = 0; i < Object.entries(pointsBySeries).length; i++) {
      /**
       * @var name {string}
       * @var seriesItemPoints {BarChartPoint[]}
       */
      const [name, seriesItemPoints] = Object.entries(pointsBySeries)[i];
      const seriesPoints = seriesItemPoints.map((point) => ({
        x: categories.indexOf(point.x),
        y: point.y,
        id: point.id,
      }));
      const seriesItem = new BarChartSeries(name, seriesColors[i], seriesPoints);
      series.push(seriesItem);
    }
    return series;
  }

  /**
   * @return {ChartLegendItem[]}
   */
  getLegendEntries() {
    return this.state.series
      .map((seriesItem) => new ChartLegendItem(seriesItem.name, seriesItem.color));
  }

  setRef(el) {
    this.ref = el;
  }

  mountChart() {
    this.highchartsChart = Highcharts.chart(this.ref, this.getChartOptions());
    if (this.props.updateLegend) {
      this.props.updateLegend(this.getLegendEntries());
    }
  }

  /**
   * Style given point in a way it stands out among others.
   * @param {?Highcharts.Point} selectedPoint
   */
  highlightSelectedPoint(selectedPoint) {
    const allSeries = this.highchartsChart.series;
    allSeries.forEach((series) => {
      series.points.forEach((point) => {
        let { color } = series;
        // Style all not selected points with a lower opacity
        if (point.id !== selectedPoint?.id) {
          color = ColorService.rgbToRgba(series.color, 0.3);
        }
        point?.update({ color });
      });
    });
  }

  removePointsHighlight() {
    const allSeries = this.highchartsChart.series;
    allSeries.forEach((series) => {
      series.points.forEach((point) => {
        const { color } = series;
        point?.update({ color });
      });
    });
  }

  /**
   * Return true if this chart is a stacked bar chart.
   * @return {boolean}
   */
  isStackedType() {
    return this.props.type === ChartConfig.TYPE_STACKED_BAR;
  }

  updateSeries() {
    this.setState({ series: this.getSeries() });
  }

  render() {
    return (
      <div className="wethod-chart-plot" ref={this.setRef} />
    );
  }
}

BarChartPlot.defaultProps = {
  updateLegend: null,
  xAxisType: 'string',
  yAxisType: 'string',
  selectedPoint: null,
};

BarChartPlot.propTypes = {
  xAxisLabel: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(BarChartPoint).isRequired,
  updateLegend: PropTypes.func,
  onPointClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf([ChartConfig.TYPE_BAR, ChartConfig.TYPE_STACKED_BAR]).isRequired,
  xAxisType: PropTypes.string,
  yAxisType: PropTypes.string,
  selectedPoint: PropTypes.instanceOf(BarChartPoint),
};

module.exports = BarChartPlot;
