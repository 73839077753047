const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('../ShowIf/ShowIf.react');
const Counter = require('./FilterAccordion/ValueCounter.react');

const FilterResume = ({ total, onReset, highlight, className, title }) => {
  const needsReset = total > 0;

  const getClassName = () => {
    let name = 'wethod-advanced-filters__filter';
    if (highlight) {
      name = `${name} wethod-advanced-filters__filter--highlight`;
    }
    if (className) {
      name = `${name} ${className}`;
    }
    return name;
  };

  return (
    <div className={getClassName()}>
      <span className="wethod-advanced-filters__label">{title}</span>
      <span className="wethod-advanced-filters__summary"><Counter value={total} name="filter-resume" /></span>
      <ShowIf condition={needsReset}>
        <button className="wethod-advanced-filters__action" type="button" onClick={onReset}>Clear all</button>
      </ShowIf>
    </div>
  );
};

FilterResume.propTypes = {
  /**
   * Total of active filters
   */
  total: PropTypes.number,
  /**
   * Function called when reset button is clicked
   */
  onReset: PropTypes.func.isRequired,
  /**
   * If true, uses a contrast background color to give importance to this component.
   */
  highlight: PropTypes.bool,
  /**
   * Additional custom class name.
   */
  className: PropTypes.string,
  title: PropTypes.string,
};

FilterResume.defaultProps = {
  total: 0,
  highlight: true,
  className: '',
  title: 'Filters applied',
};

module.exports = FilterResume;
