const React = require('react');
const PropTypes = require('prop-types');

const ChartSidebarDimensionOption = ({ children }) => (
  <div className="wethod-chart-sidebar__dimension-options">{children}</div>
);

ChartSidebarDimensionOption.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = ChartSidebarDimensionOption;
